import ReferralInvitation from "./ReferralInvitation";
import ContactList from "./ContactList";
import { useEffect, useState } from "react";
import useAppConfig from "../../hooks/useAppConfig";
import Footer from "../../Layout/Footer";
import HeroBanner from "../../Layout/HeroBanner";
import { ReferFriendContainer } from "../../styles/styledComponent";
import ReferralForm from "./ReferralForm";
const ReferFriendV2 = () => {
  const [contactPickerEnabled, setContactPickerEnabled] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [clearContacts, setClearContacts] = useState(false);
  const pwaConfig = useAppConfig();
  const headerImage = pwaConfig?.appConfig?.config?.modules?.LOYALTY
    ?.headerImage
    ? pwaConfig?.appConfig?.config?.modules?.LOYALTY?.headerImage
    : pwaConfig?.appConfig?.config?.modules?.HOME?.headerImage;
  const footerImage = pwaConfig?.appConfig?.config?.modules?.LOYALTY
    ?.footerImage
    ? pwaConfig?.appConfig?.config?.modules?.LOYALTY?.footerImage
    : pwaConfig?.appConfig?.config?.modules?.HOME?.footerImage;

  useEffect(() => {
    if (clearContacts) {
      setContacts([]);
      setClearContacts(false);
    }
  }, [clearContacts]);

  const handleSelectContacts = async () => {
    if ("contacts" in navigator && "ContactsManager" in window) {
      try {
        const props = await navigator.contacts.getProperties();
        const selectedContacts = await navigator.contacts.select(props, {
          multiple: true,
        });

        const newContacts = selectedContacts.filter((newContact) => {
          return !contacts.some(
            (existingContact) =>
              existingContact.tel &&
              newContact.tel &&
              existingContact.tel[0] === newContact.tel[0]
          );
        });

        setContacts((prevContacts) => [...prevContacts, ...newContacts]);
      } catch (error) {
        if (error.name === "TypeError") {
          setContactPickerEnabled(false);
        } else {
          console.error("Error fetching contacts:", error);
        }
      }
    } else {
      setContactPickerEnabled(false);
      console.error("Contacts API not supported");
    }
  };

  const handleRemoveContact = (index) => {
    setContacts((prevContacts) => prevContacts.filter((_, i) => i !== index));
  };

  return (
    <ReferFriendContainer>
      <HeroBanner src={headerImage} />
      {contactPickerEnabled ?
       (
        contacts.length > 0 ? (
          <ContactList
            contacts={contacts}
            handleSelectContacts={handleSelectContacts}
            handleRemoveContact={handleRemoveContact}
            setClearContacts={setClearContacts}
          />
        ) : (
          <ReferralInvitation handleSelectContacts={handleSelectContacts} />
        )
      ) : (
        <ReferralForm />
      )
      }
      <Footer img={footerImage} />
    </ReferFriendContainer>
  );
};

export default ReferFriendV2;
